import '@assetsPortalFacil/scss/admin/components/admin/DynamicForm/FormAccordion.scss'

import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Col, Row } from 'reactstrap'

import Proptypes from 'prop-types'
import classnames from 'classnames'
import { useState } from 'react'

export default function FormAccordion({
    title,
    children,
    showBorder,
    showOpen = true,
    colSize = 12,
    visible = true,
    className
}) {
    //const [open, setOpen] = useState('1')
    const [open, setOpen] = useState(showOpen ? '1' : false)
    const toggle = id => {
        open === id ? setOpen() : setOpen(id)
    }

    //#region return
    return (
        <Col
            md={colSize}
            sm="12"
            className={classnames(`formAccordionCol ${className}`, {
                hidden: !visible,
                active: 1 === 1
            })}
            // className={visible ? 'formAccordionCol' : 'hidden'}
        >
            <Accordion open={open} toggle={toggle} className={showBorder ? 'accordion-border' : ''}>
                <AccordionItem>
                    <AccordionHeader targetId="1">{title}</AccordionHeader>
                    <AccordionBody accordionId="1">
                        <Row>{children}</Row>
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        </Col>
    )
    //#endregion
}

FormAccordion.propTypes = {
    title: Proptypes.oneOfType([Proptypes.string, Proptypes.object]).isRequired,
    showOpen: Proptypes.bool.isRequired,
    showBorder: Proptypes.bool.isRequired
}

FormAccordion.defaultProps = {
    showOpen: true,
    showBorder: true
}
